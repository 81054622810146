<template>
  <div style="background-color: #2196f3" class="fullScreen">
    <div class="center">
      <div style="font-size: 30vh; color: #fff" class="p-text-center">404</div>
      <div class="p-text-center" style="opacity: 0.4; color: #fff">
        <h3>Ops. Nada aqui...</h3>
      </div>
      <div class="p-d-flex p-ai-center p-jc-center">
        <Button
          class="p-button-lg p-button-raised p-button-text"
          icon="pi pi-send"
          v-tooltip.bottom="'CLIQUE PARA VOLTAR'"
          style="background-color: #fff"
          @click="logar"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error404",
  methods: {
    async logar() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.fullScreen {
  height: 100vh;
  overflow: hidden;
}
.center {
  margin-top: 180px;
}
</style>  

